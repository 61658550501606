/***************************************************************************************************************************************************************************
*
* Mise en forme générale de l'application
* Utilisation de css Grid et Flex
*
***************************************************************************************************************************************************************************/


/***********************************************************************************************************************
*
* Réglages des principaux éléments
*
***********************************************************************************************************************/

html {
    margin: 0;
    padding: 0;
    font-size: 62.5%;
}

body {
    height: auto;
    min-height: 100%;
    font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 400;
    overflow-x: hidden;
    overflow-y: auto;
    display: grid;
    grid-template-areas: "head head" "nav main" "nav footer";
    grid-template-rows: 53px 1fr 40px;
    grid-template-columns: 230px 1fr;
    height: 10vh;
    margin: 0;
    padding: 0;
    font-size: 14px !important;
    line-height: 1.42857143;
    color: #333;
    background: url("../img/background.png") fixed center/cover border-box;
}

h1 {
    font-size: 26px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 22px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

.btn {
    font-size: 15px;
}

.form-control {
    font-size: 15px;
}

.has-feedback {
    position: relative;
}

.form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    pointer-events: none;
}

.form-control {
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.login-box-body,
.register-box-body {
    border: 1px solid #ececec;
    border-radius: 5px;
}

.login-page {
    background: url('../img/background.jpg') no-repeat center/cover;
}

caption {
    caption-side: top;
    text-align: center;
    font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: bolder;
    color: black;
}

.modal{
    background: white!important;
    top: 0!important;
    right: 0!important;
    max-width: 700px!important;
}

.modal a.close-modal{
    top: 0px!important;
    right: 0px!important;
}
.blocker{
    z-index:1000!important;
}

progress{
    box-sizing: border-box;
    padding: 3px 3px 2px 3px;
    background: #006026;
    border: 1px solid rgba(0,0,0,.5);
    border-radius: 12px;
    box-shadow: 0 1px 0 rgba(255,255,255,.2);
}
progress::-moz-progress-bar {
    background-color: #00963e;
    border-radius: 12px;
  }
progress::-webkit-progress-value {
    background-color: #00963e;
    border-radius: 12px;
}
progress::-ms-fill {
    background-color: #00963e;
    border-radius: 12px;
  }
/***************************************** Modification des classes .box **********************************************/

.box {
    position: relative;
    border-radius: 3px;
    background: rgba(255, 255, 255, 0);
    border-top: 3px solid #d2d6de;
    border-top-color: rgb(210, 214, 222);
    margin-bottom: 0;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.box-body {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    padding: 5px 10px;
}


/************************* Modification des padding de la pagination du plugin Datatable ******************************/

.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 0 !important;
}


/***************************************************************************************************************************************************************************
*
* Mise en forme des parties divers de l'appli
*
***************************************************************************************************************************************************************************/


/***********************************************************************************************************************
*
* Mise en forme du layout baseApp
*
***********************************************************************************************************************/

header {
    grid-area: head;
    display: grid;
    grid-template-columns: 230px 1fr;
}

.logo {
    grid-column: 1;
}

nav {
    grid-column: 2;
    margin-left: 0 !important;
}

.navigation {
    grid-area: nav;
}

section {
    grid-area: main;
    display: grid;
    grid-template-rows: 26px 1fr;
    height: 90.1vh
}

footer {
    grid-area: footer;
    margin-left: 0 !important;
}

.main-footer {
    padding: 10px 15px;
    background-color: rgba(255, 255, 255, 1) !important;
}

.navbar {
    padding: 0 1rem !important;
}


/***********************************************************************************************************************
*
* Mise en forme du header
*
***********************************************************************************************************************/

.main-header {
    position: fixed;
    width: 100%;
    max-height: 53px !important;
}

.main-header .logo {
    height: 210px !important;
    border-bottom: 1px solid #d2d6de !important;
    padding: 0 !important;
}

.main-header .logo span .logo1 {
    position: relative;
    z-index: 10;
    top: 0;
    max-width: 226px;
    height: auto;
    color: black;
}

.logo2 {
    animation-duration: 3s;
    animation-name: logo1;
}

@keyframes logo1 {
    from {
        top: 153px;
    }
    to {
        top: 0px;
    }
}

.user {
    background-color: rgba(255, 255, 255, 0.8);
    /* padding-top: 15px;
    padding-bottom: 15px; */
    padding-right: 15px;
    border-radius: 5px;
}

#hide {
    visibility: hidden;
}

.main-header .sidebar-toggle {
    float: right;
    margin-top: 1px;
    background-color: transparent;
    background-image: none;
    padding: 15px 15px;
}


/***********************************************************************************************************************
*
* Mise en forme du menu dans la sidebar
*
***********************************************************************************************************************/

.navigation {
    position: fixed;
    z-index: 1050;
    top: 53px;
    width: 230px;
    height: calc(100% - 53px);
    background-color: #00963e;
}

.navigation li.header {
    font-size: 14px !important;
}

.skin-sudalys .navigation a {
    color: #c9eeff;
}

.skin-sudalys .sidebar-menu .treeview-menu>li>a {
    font-size: 14px;
    font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 400;
}

.menu-tab {
    display: inline-block;
    text-align: center;
    padding: 10px 15px;
    border-radius: 4px;
    margin-right: 0.5%;
}

.menu-tab h3 {
    display: block;
    width: 100%;
    height: auto;
    text-shadow: 0 1px 1px black;
}

.sidebar-menu>li.header {
    color: #fff;
    background: #00963e;
}


/***********************************************************************************************************************
*
* Mise en forme du menu des options cartographiques et import de shapes
*
***********************************************************************************************************************/

#menuCarto {
    position: absolute;
    z-index: 750;
    top: 53px;
    left: -230px;
    width: 230px;
    height: calc(100% - 53px);
    background-color: #00963e;
    border-left: 1px black solid;
    padding: 10px 0;
}

#dropzone {
    left: -200px;
}

.close {
    position: relative;
    top: -5px;
    right: 5px;
}

#dropzone>.close {
    top: 2px;
    right: 18px;
}

#spinner {
    animation-name: spinnerRotate;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spinnerRotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#rapport {
    padding: 7px 10px 6px;
}

#export>a {
    min-width: 50px;
    text-shadow: 0 0 1px black;
    box-shadow: 1px 1px 3px black;
    border-radius: 5px;
    margin-right: 5px;
}

#export>a:hover {
    box-shadow: inset 1px 1px 3px black;
}

#export>a :last-child {
    margin-right: 0px;
}


/***********************************************************************************************************************
*
* Mise en forme du fil d'ariane
*
***********************************************************************************************************************/

#wo-breadcrumbs {
    grid-row: 1;
}

.breadcrumb {
    margin: 0;
    background-color: rgba(255, 255, 255, 0);
    font-size: 12px;
    padding: 5px 15px;
}


/**********************************************************************************************************************************************************************
*
* Mise en forme des parties de l'exploitation
*
**********************************************************************************************************************************************************************/


/***********************************************************************************************************************
*
* Mise en forme de la page d'accueil
*
***********************************************************************************************************************/

.box.box-info {
    border-top-color: #00963f !important;
}

#accueil {
    grid-row: 2;
    margin-top: -26px;
}
#infos{
    height: 82.2vh;
}
#infos>div>#map {
    height: calc(100vh - 190px);
}


/***********************************************************************************************************************
*
* Mise en forme des pages sites techniques, points techniques et sites utilisateurs finaux
*
***********************************************************************************************************************/

#pointsTechniques,
#sitestech,
#suf {
    grid-row: 2;
    display: grid;
    grid-template-areas: "head head" "info map";
    grid-template-rows: 41px 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px 10px;
    padding: 0 10px 10px;
}

#pointsTechniques>.box-header,
#sitestech>.box-header,
#suf>.box-header {
    grid-area: head;
}

#pointsTechniques>#infos,
#sitestech>#infos,
#suf>#infos {
    grid-area: info;
}

#pointsTechniques>#map,
#sitestech>#map,
#suf>#map {
    grid-area: map;
}

#map>#Map {
    height: calc(100vh - 183px);
}


/***********************************************************************************************************************
*
* Mise en forme de la page des routes optiques et des synoptiques
*
***********************************************************************************************************************/

#routeOptique {
    grid-row: 2;
    display: grid;
    grid-template-areas: "head head" "info map" "syno syno";
    grid-template-rows: 41px 1fr auto;
    grid-template-columns: 0.75fr 1fr;
    grid-gap: 10px 10px;
    margin: 0;
}

#routeOptique>.box-header {
    grid-area: head;
}

#routeOptique>#infos {
    grid-area: info;
    margin-left: 10px;
}

#routeOptique>#routeOptiqueMap {
    grid-area: map;
    margin-right: 10px;
}

#routeOptique>#routeOptiqueMap>#Map {
    height: 70.5vh;
    min-height: 100%;
}

#routeOptique>#syno-container {
    grid-area: syno;
}

#info-syno {
    display: grid;
    grid-template-columns: 1fr 0.5fr 0.75fr;
    grid-gap: 5px;
}

.info-syno-element {
    display: block;
    justify-self: center;
}

.info-syno-element span {
    display: inline-block;
}

#syno-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 10px 15px;
    background-color: #d8dce1;
    margin-bottom: 10px;
}

.syno-box {
    border: 1px solid black;
    border-radius: 5px;
    font-size: 14px;
    margin: 15px 0 5px;
    padding: 5px 10px 0;
    background-color: #FFFFFF;
    height: 48px;
    text-align: center;
}

.syno-cable {
    font-size: 12px;
    text-align: center;
}

.syno-cable-fibre {
    min-width: 95px;
    height: 4px;
}

.syno-box-cumul {
    position: relative;
    text-align: center;
    font-size: 12px;
}


/***********************************************************************************************************************
*
* Mise en forme de la page des baies et bpes
*
***********************************************************************************************************************/

#baies,
#BPES {
    grid-row: 2;
    display: grid;
    grid-template-areas: "head" "info";
    grid-template-rows: 41px 1fr;
    padding: 10px;
}

#baies>.box-header,
#BPES>.box-header {
    grid-area: head;
}

#baies>#infos,
#BPES>#infos {
    grid-area: info;
    min-height: calc(100vh - 183px);
}


/***********************************************************************************************************************
*
* Mise en forme de la page local technique
*
***********************************************************************************************************************/

#localTech {
    grid-row: 2;
    display: grid;
    grid-template-areas: "head" "local" "image";
    grid-template-rows: 41px repeat(2, min-content);
    grid-row-gap: 10px;
    padding: 10px;
}

#localTech>.box-header {
    grid-area: head;
}

#localTech>#locaux {
    grid-area: local;
}

#localTech>#locaux>h3 {
    margin-left: 25px;
    font-size: 16px;
}

#localTech>#locaux>.local {
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: 1fr repeat(5, 1fr);
    border-top: 1px solid #f4f4f4;
    border-left: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
    margin-bottom: 10px;
}

#localTech>#locaux>.baie {
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: 1fr repeat(5, 1fr);
    border-top: 1px solid #f4f4f4;
    border-left: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
    margin-bottom: 10px;
}

#localTech>#locaux>.local>span {
    padding: 5px;
    border-right: 1px solid #f4f4f4;
}

#localTech>#locaux>.local>span:nth-child(1n+7) {
    background-color: #f9f9f9;
}

#localTech>#locaux>.baie>span {
    padding: 5px;
    border-right: 1px solid #f4f4f4;
}

#localTech>#locaux>.baie>span:nth-child(1n+7) {
    background-color: #f9f9f9;
}

#localTech>#locaux>.baie>span>a>.btn {
    padding: 0 5px !important;
    width: auto !important;
}

#localTech>#localImage {
    grid-area: image;
    max-width: 90%;
    min-width: 300px;
    margin: auto;
}


/***********************************************************************************************************************
*
* Mise en forme de la page pour l'affichage d'un site utilisateur
*
***********************************************************************************************************************/

.suf {
    display: grid;
    grid-template-areas: "title" "onglet" "body";
    grid-template-rows: 45px 45px auto;
}

#sufTtitle {
    grid-area: title;
    line-height: 45px;
    margin: 0;
}

#sufOnglet {
    grid-area: onglet;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-column-gap: 5px;
    text-align: center;
}

#sufOnglet h3 {
    display: block;
    height: 45px;
    line-height: 45px;
    margin: 0;
    border-top: 1px solid darkslategray;
    border-left: 1px solid darkslategray;
    border-right: 1px solid darkslategray;
    border-radius: 4px 4px 0 0;
    cursor: pointer;
    background-color: #eceff1;
}

.actif {
    background-color: #f5f8fa! important;
    height: 46px !important;
    z-index: 500;
}

#sufBody {
    grid-area: body;
    border: 1px solid darkslategray;
    border-radius: 0 4px 4px 4px;
    background-color: #f5f8fa;
    padding: 15px 30px;
    height: auto;
}


/* #sufBody div {
    width: 100%;
} */

#sufBody div label {
    color: black;
    width: 25%;
    text-align: left;
    display: inline-block;
    padding: 25px;
    font-size: 100%;
}

#sufBody div p {
    width: 74%;
    text-align: left;
    display: inline-block;
}

#sufBody div .connecteur {
    background-color: #90ee90;
    color: darkslategrey;
    border: 1px solid black;
    text-align: center;
    width: auto;
    height: 100%;
    border-radius: 5px;
    display: flex;
}

#loc {
    position: relative;
}

#infoTech {
    position: relative;
}

#acces {
    position: relative;
}


/***********************************************************************************************************************
*
* Mise en forme des tiroirs et connecteurs
*
***********************************************************************************************************************/

#tiroirs {
    grid-row: 2;
    display: grid;
    grid-template-areas: "head" "info";
    grid-template-rows: 41px 1fr;
    padding: 10px;
    margin-bottom: 0;
}

#tiroirs>.box-header {
    grid-area: head;
}

#tiroirs>#infos {
    grid-area: info;
    display: grid;
    grid-template-columns: 0.75fr 1fr;
    grid-column-gap: 10px;
    margin-top: 10px;
}

#infos>#baie {
    grid-column: 1;
}

#infos>#tiroir {
    grid-column: 2;
}

#baie-header {
    display: grid;
    grid-template-columns: 1fr 5fr;
    width: 100%;
    border: 1px solid #f4f4f4;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}

#baie-header h2:first-child {
    grid-column: 1;
}

#baie-header h2:last-child {
    grid-column: 2;
}

#baie-tiroirs {
    display: grid;
    grid-template-columns: 1fr 5fr;
    width: 100%;
    border: 1px solid #f4f4f4;
    text-align: center;
    max-height: 75vh;
    padding: 0;
    overflow-y: scroll;
}

.U {
    grid-column: 1;
    height: 30px;
}

.tiroir {
    display: flex;
    grid-column: 2;
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: blue;
    background-color: #87ceeb;
    border-top: 1px solid black;
    text-align: center;
}

.tiroir>p {
    margin: auto;
}

#connecteurs {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 3vh;
    grid-gap: 2px;
    padding: 0;
}

.connecteur {
    background-color: #90ee90;
    color: darkslategrey;
    border: 1px solid black;
    text-align: center;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: flex;
}

.connecteur p {
    text-align: center;
    margin: auto;
}

#info {
    position: absolute;
    z-index: 25;
    background-color: snow;
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px;
    width: auto;
    min-width: 100px;
    display: none;
    margin: 3vh -3vh;
}


/***********************************************************************************************************************
*
* Mise en forme des pages des Boitiers de protection d'épissures et des cassettes
*
***********************************************************************************************************************/

#BPE {
    grid-row: 2;
    display: grid;
    grid-auto-rows: min-content;
}

#BPEpTech {
    grid-row: 2;
    display: grid;
    grid-auto-rows: min-content;
    padding: 10px;
    margin: 0;
}

#BPE>.box-header,
#BPEpTech>#pTech {
    grid-row: 1;
}

#BPE>.cassette-header,
#BPEpTech>.box-header {
    grid-row: 2;
}

#BPE>.cassette-body,
#BPEpTech>.cassette-header {
    grid-row: 3;
}

#BPEpTech>.cassette-body {
    grid-row: 4;
}

#BPEpTech>#pTech {
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: 1fr repeat(3, 1fr);
    border-top: 1px solid #f4f4f4;
    border-left: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
    margin-bottom: 10px;
}

#BPEpTech>#pTech>span {
    padding: 5px;
    border-right: 1px solid #f4f4f4;
}

#BPEpTech>#pTech>span:nth-child(1n+5) {
    background-color: #f9f9f9;
}


/***********************************************************************************************************************
*
* Mise en forme des cassettes
*
***********************************************************************************************************************/

.cassette-header {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 1fr 1fr 1fr 1fr 3fr 1fr;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    border: 1px solid #f4f4f4;
    margin: 0 27px 0 10px;
}

.cassette-header div {
    padding: 10px 0;
    font-weight: bold;
}

.cassette-body {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 1fr 1fr 1fr 1fr 3fr 1fr;
    padding: 0;
    margin: 0 10px;
    text-align: center;
    text-shadow: 1px 1px 0 #fff;
    height: 72.5vh;
    overflow-x: hidden;
    border-bottom: 1px solid #f4f4f4;
}

.cassette-body-pTechBpe {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 1fr 1fr 1fr 1fr 3fr 1fr;
    padding: 0;
    text-align: center;
    text-shadow: 1px 1px 0 #fff;
    height: 64.5vh;
    overflow-x: hidden;
    border-bottom: 1px solid #f4f4f4;
}

.cassette-body div,
.cassette-body-pTechBpe div {
    padding: 10px 0;
    border-bottom: 1px solid #f4f4f4;
}

.tube_g {
    border-bottom: 1px solid #f4f4f4;
    border-left: 1px solid #f4f4f4;
    border-right: 1px solid #f4f4f4;
}

.tube_d {
    border-bottom: 1px solid #f4f4f4;
    border-right: 1px solid #f4f4f4;
}

.fibre_g {
    border-bottom: 1px solid #f4f4f4;
    border-right: 1px solid #f4f4f4;
}

.fibre_d {
    border-bottom: 1px solid #f4f4f4;
    border-left: 1px solid #f4f4f4;
    border-right: 1px solid #f4f4f4;
}

.fibre_suivie {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000 !important;
    background-color: lightgrey;
}


/***********************************************************************************************************************
*
* Mise en forme des masques de chambres
*
***********************************************************************************************************************/

#masqueContent {
    grid-row: 2;
    display: grid;
    grid-template-areas: "head head head" "A1 A2 A3" "B4 B5 B6" "C7 C8 C9" "info info info";
    grid-template-rows: 41px repeat(4, 0.5fr);
    grid-template-columns: 1fr 2fr 1fr;
}

#masqueContent>.box-header {
    grid-area: head;
}

#masqueA {
    grid-area: B4;
}

#masqueB {
    grid-area: B6;
}

#masqueC {
    grid-area: A2;
}

#masqueD {
    grid-area: C8;
}

#legende {
    grid-area: C9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#masqueContent>#infos {
    grid-area: info;
    display: grid;
    grid-template-columns: repeat(8, auto);
    grid-template-rows: auto;
    grid-column-gap: 5px;
    margin: 15px 10px;
    text-align: center;
    border: 1px solid black;
}

#masqueContent>#infos>.entete {
    font-weight: bold;
    padding: 5px;
    border-bottom: 1px solid #f4f4f4;
}

.infoMasque {
    grid-column: 1;
    text-align: center;
    font-weight: bold;
    padding: 5px;
    border-bottom: 1px solid #f4f4f4;
}

.infoAlveole {
    grid-column: 2;
    text-align: center;
    padding: 5px;
    border-bottom: 1px solid #f4f4f4;
}

.infoFourreaux {
    grid-column: 3;
    text-align: center;
    padding: 5px;
    border-bottom: 1px solid #f4f4f4;
}

.infoCable {
    grid-column: 4;
    text-align: center;
    padding: 5px;
    border-bottom: 1px solid #f4f4f4;
}

.infoType {
    grid-column: 5;
    text-align: center;
    padding: 5px;
    border-bottom: 1px solid #f4f4f4;
}

.infoProprio {
    grid-column: 6;
    text-align: center;
    padding: 5px;
    border-bottom: 1px solid #f4f4f4;
}

.infoOccupation {
    grid-column: 7;
    text-align: center;
    padding: 5px;
    border-bottom: 1px solid #f4f4f4;
}

.datePose {
    grid-column: 8;
    text-align: center;
    padding: 5px;
    border-bottom: 1px solid #f4f4f4;
}

.masque {
    margin-bottom: 0;
}

.masque h2 {
    text-align: center;
    margin: 5px;
}

.masque img {
    display: block;
    margin: auto;
}

.face1 {
    border-right: 1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    background-color: #a9a9a9;
    margin: auto;
    width: 50.69%;
    height: auto;
    min-height: 15rem;
    position: relative;
}

.face2 {
    border-right: 1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    background-color: #a9a9a9;
    margin: auto;
    width: 50.69%;
    min-height: 15rem;
    position: relative;
}

.faceContent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    grid-auto-rows: 1fr;
    grid-row-gap: 5px;
    align-items: center;
    justify-items: center;
}

.trou {
    border: 1px solid black;
    vertical-align: bottom;
    position: relative;
}

.trou>span {
    position: absolute;
    width: 120px;
    white-space: nowrap;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    text-align: center;
}

#legende-content {
    border-radius: 10px;
    border: 1px solid black;
    display: grid;
    grid-row-gap: 5px;
    padding: 10px;
    align-items: center;
    justify-items: center;
    min-width: 50%;
}


/***************************************************************************************************************************************************************************
*
* Mise en forme des pages usagers
*
***************************************************************************************************************************************************************************/


/***********************************************************************************************************************
*
* Mise en forme de la page de la liste des usagers
*
***********************************************************************************************************************/

#Usagers {
    grid-row: 2;
    display: grid;
    grid-auto-rows: min-content;
    padding: 10px;
}


/***************************************************************************************************************************************************************************
*
* Mise en forme des pages utilisateurs
*
***************************************************************************************************************************************************************************/


/***********************************************************************************************************************
*
* Mise en forme de la page de la liste des utilisateurs
*
***********************************************************************************************************************/
#ssprojet,
#projet,
#users,
#user {
    grid-row: 2;
    display: grid;
    grid-auto-rows: min-content;
    padding: 0 10px;
}

#ssprojet>#infos{
    grid-row: 2;
    min-height: 82.7vh;
    display: grid;
}

#ssprojet>#infos>div{
    margin: auto 0;
}

#projet>#infos{
    grid-row: 2;
    height: 82.7vh;
}
#usersAjout,
#bddAjout {
    grid-row: 2;
    display: grid;
    grid-auto-rows: min-content;
    grid-row-gap: 10px;
    padding: 0 10px;
}

#users>.box-header,
#user>.box-header,
#usersAjout>.boxheader {
    grid-row: 1;
}

#users>#infos {
    grid-row: 2;
    min-height: 83vh;
}

#ssprojet>#infos>div>#mapOk {
    display: grid;
    grid-template-columns: 0.12fr 1fr;
    grid-column-gap: 10px;
}

#ssprojet>#infos>div>#mapOk>#mapButton{
    grid-column: 1;
}

#ssprojet>#infos>div>div>div>select,
#ssprojet>#infos>div>div>div>div>div>select
{
    max-width: 350px;
}

#ssprojet>#infos>div>#mapOk>#map{
    grid-column: 2;
    min-height:82vh;
}

#usersAjout>form,
#user>#infos {
    grid-row: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 81.9vh;
}

#usersAjout>form>div,
#user>#infos>div {
    width: 50%;
    margin: 10px 0;
}

#usersAjout>form>div>label,
#user>#infos>div>label {
    min-width: 20% !important;
}

#user>#infos>div>ul {
    display: inline-block !important;
    max-width: 50% !important;
    margin-left: -40px !important;
}

#user>#infos>div>ul>li {
    display: inline-block !important;
    max-width: 50% !important;
}

#usersAjout>form>div>input,
#usersAjout>form>div>select {
    display: inline-block !important;
    max-width: 75% !important;
    vertical-align: top;
}

.modifUsers {
    height: 76.1vh;
}

#deleteUsers {
    grid-row: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 119px);
}

#deleteUsers>.box-header {
    width: 100%;
    text-align: left;
}

#deleteUsers p {
    margin: 25px 0;
    text-align: center;
}

#deleteUsers>form {
    grid-row: 3;
    height: 66.4vh;
    width: 50%;
    margin: 10px auto;
    text-align: center;
}


/**********************************************************************************************************************************************************************
*
* Mise en forme de la partie administration
*
**********************************************************************************************************************************************************************/


/***********************************************************************************************************************
*
* Mise en forme de la page d'accueil
*
***********************************************************************************************************************/

#indexadmin {
    grid-row: 2;
    min-height: calc(100vh - 119px);
}


/***************************************************************************************************************************************************************************
*
* Mise en forme des média quéries
*
***************************************************************************************************************************************************************************/


/***********************************************************************************************************************
*
* Réglages de l'affichage par résolution d'écran
*
***********************************************************************************************************************/

@media screen and (min-width: 900px) and (max-width: 1152px) {
    #pointsTechniques,
    #sitestech,
    #suf {
        display: grid;
        grid-template-areas: "head" "info" "map";
        grid-template-rows: 41px auto auto;
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
        padding: 0 10px;
    }
    #SiteTechMap,
    #PointTechMap,
    #sufMap {
        height: 53vh;
    }
}

@media screen and (min-width:480px) and (max-width: 899px) {
    body {
        display: grid;
        grid-template-areas: "head" "main" "footer";
        grid-template-rows: 53px 0.75fr 40px;
        grid-template-columns: 1fr;
        height: 10vh;
        max-height: 100%;
        margin: 0;
    }
    #hide {
        display: inherit;
        margin-right: 15px;
    }
    .user {
        display: none;
    }
    .navigation {
        position: absolute;
        margin-left: -230px;
        z-index: 1030;
    }
    #indexMap {
        height: 80vh;
        min-height: 100%;
    }
    #pointsTechniques,
    #sitestech,
    #suf {
        display: grid;
        grid-template-areas: "head" "info" "map";
        grid-template-rows: 41px auto auto;
        grid-template-columns: 1fr;
        grid-gap: 0;
        grid-row-gap: 10px;
        padding: 0 10px;
    }
    #SiteTechMap,
    #PointTechMap,
    #sufMap {
        height: 53vh;
    }
}

@media screen and (max-width:479px) {
    body {
        display: grid;
        grid-template-areas: "head" "main" "footer";
        grid-template-rows: 53px 1fr 40px;
        grid-template-columns: 1fr;
        height: 100vh;
        min-height: 100%;
        margin: 0;
    }
    #hide {
        display: inherit;
        margin-right: 15px;
    }
    .user {
        display: none;
    }
    .navigation {
        position: absolute;
        margin-left: -230px;
        z-index: 1030;
    }
    #indexMap {
        height: 80vh;
        min-height: 100%;
    }
    #pointsTechniques,
    #sitestech,
    #suf {
        display: grid;
        grid-template-areas: "head" "info" "map";
        grid-template-rows: 41px auto auto;
        grid-template-columns: 1fr;
        grid-gap: 0;
        grid-row-gap: 10px;
        padding: 0;
    }
    #SiteTechMap,
    #PointTechMap,
    #sufMap {
        height: 53vh;
    }
}