/*
 * Skin: sudalys
 * ------------
 */

.main-header .navbar {
    height: 53px;
    border-bottom: 1px solid #d2d6de;
}

.skin-sudalys .main-header .navbar {
    background-color: rgba(255, 255, 255, 1);
}

.skin-sudalys .main-header .navbar .nav>li>a {
    color: #ffffff;
}

.skin-sudalys .main-header .navbar .nav>li>a:hover,
.skin-sudalys .main-header .navbar .nav>li>a:active,
.skin-sudalys .main-header .navbar .nav>li>a:focus,
.skin-sudalys .main-header .navbar .nav .open>a,
.skin-sudalys .main-header .navbar .nav .open>a:hover,
.skin-sudalys .main-header .navbar .nav .open>a:focus,
.skin-sudalys .main-header .navbar .nav>.active>a {
    background-color: #ecf0f5;
    color: #f6f6f6;
}

.skin-sudalys .main-header .navbar .sidebar-toggle {
    color: #ffffff;
}

.skin-sudalys .main-header .navbar .sidebar-toggle:hover {
    color: #000;
    background: rgba(0, 0, 0, 0.1);
}

.skin-sudalys .main-header .navbar .sidebar-toggle {
    color: #000;
}

.skin-sudalys .main-header .navbar .sidebar-toggle:hover {
    background-color: #ecf0f5;
}

@media (max-width: 767px) {
    .skin-sudalys .main-header .navbar .dropdown-menu li.divider {
        background-color: rgba(255, 255, 255, 0.1);
    }
    .skin-sudalys .main-header .navbar .dropdown-menu li a {
        color: #fff;
    }
    .skin-sudalys .main-header .navbar .dropdown-menu li a:hover {
        background: #e08e0b;
    }
}

.skin-sudalys .main-header .logo {
    background-color: #fff;
    color: #ffffff;
    border-bottom: 0 solid transparent;
}

.skin-sudalys .main-header .logo:hover {
    background-color: #fff;
}

.skin-sudalys .main-header li.user-header {
    background-color: #f39c12;
}

.skin-sudalys .content-header {
    background: transparent;
}

.skin-sudalys .wrapper,
.skin-sudalys .main-sidebar,
.skin-sudalys .left-side {
    background-color: #356fb4;
}

.skin-sudalys .user-panel>.info,
.skin-sudalys .user-panel>.info>a {
    color: #fff;
}

.skin-sudalys .sidebar-menu>li.header {
    color: #fff;
    background: #00963e;
}

.skin-sudalys .sidebar-menu>li>a {
    border-left: 3px solid transparent;
}

.skin-sudalys .sidebar-menu>li:hover>a,
.skin-sudalys .sidebar-menu>li.active>a,
.skin-sudalys .sidebar-menu>li.menu-open>a {
    color: #ffffff;
    background: #10662e;
}

.skin-sudalys .sidebar-menu>li.active>a {
    border-left-color: #96c11e;
}

.skin-sudalys .sidebar-menu>li>.treeview-menu {
    margin: 0 1px;
    background: #08883c;
}

.skin-sudalys .sidebar a {
    color: #b8c7ce;
}

.skin-sudalys .sidebar a:hover {
    text-decoration: none;
}

.skin-sudalys .sidebar-menu .treeview-menu>li>a {
    color: #d3d3d3;
    font-size: 14px;
}

.skin-sudalys .sidebar-menu .treeview-menu>li.active>a,
.skin-sudalys .sidebar-menu .treeview-menu>li>a:hover,
.skin-sudalys .sidebar-menu .treeview-menu>li.active>ul>li>a,
.skin-sudalys .sidebar-menu .treeview-menu>li>ul>li>a:hover {
    color: #ffffff;
}

.skin-sudalys .sidebar-form {
    border-radius: 3px;
    border: 1px solid #374850;
    margin: 10px 10px;
}

.skin-sudalys .sidebar-form input[type="text"],
.skin-sudalys .sidebar-form .btn {
    box-shadow: none;
    background-color: #374850;
    border: 1px solid transparent;
    height: 35px;
}

.skin-sudalys .sidebar-form input[type="text"] {
    color: #666;
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px;
}

.skin-sudalys .sidebar-form input[type="text"]:focus,
.skin-sudalys .sidebar-form input[type="text"]:focus+.input-group-btn .btn {
    background-color: #fff;
    color: #666;
}

.skin-sudalys .sidebar-form input[type="text"]:focus+.input-group-btn .btn {
    border-left-color: #fff;
}

.skin-sudalys .sidebar-form .btn {
    color: #999;
    border-top-left-radius: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 0;
}

.box {
    box-shadow: none;
}