@font-face {
    font-family: BebasNeueRegular;
    src: url(fonts/BebasNeue-webfont.woff);
}

@font-face {
    font-family: Alegreya-Regular;
    src: url(fonts/Alegreya-Regular.ttf);
}

@font-face {
    font-family: Ubuntu-Medium;
    src: url(fonts/Ubuntu-Medium.ttf);
}

@font-face {
    font-family: Ubuntu-Regular;
    src: url(fonts/Ubuntu-Regular.ttf);
}



.ac-container{
	width: auto;
	text-align: left;
	overflow-y: auto;
	overflow-x: hidden;
	height: auto;
}

div[id^="leaflet-control-accordion-layers"] > label {
	font-family: 'BebasNeueRegular', 'Arial Narrow', Arial, sans-serif;
	padding: 5px 20px;
	position: relative;
	z-index: 20;
	display: block;
	height: 30px;
	cursor: pointer;
	color: #777;
	text-shadow: 1px 1px 1px rgba(255,255,255,0.8);
	line-height: 33px;
	font-size: 19px;
	background: #ffffff;
	background: -moz-linear-gradient(top, #ffffff 1%, #eaeaea 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(1%,#ffffff), color-stop(100%,#eaeaea));
	background: -webkit-linear-gradient(top, #ffffff 1%,#eaeaea 100%);
	background: -o-linear-gradient(top, #ffffff 1%,#eaeaea 100%);
	background: -ms-linear-gradient(top, #ffffff 1%,#eaeaea 100%);
	background: linear-gradient(top, #ffffff 1%,#eaeaea 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eaeaea',GradientType=0 );
	box-shadow:
		0px 0px 0px 1px rgba(155,155,155,0.3),
		1px 0px 0px 0px rgba(255,255,255,0.9) inset,
		0px 2px 2px rgba(0,0,0,0.1);
    box-sizing: content-box;
    cursor:pointer;
    width: auto;
    margin: 0;
}
div[id^="leaflet-control-accordion-layers"] > label:hover {
	background: #fff;
}
.ac-container input.menu:checked + label,
.ac-container input.menu:checked + label:hover{
	background: #c6e1ec;
	color: #3d7489;
	text-shadow: 0px 1px 1px rgba(255,255,255, 0.6);
	box-shadow:
		0px 0px 0px 1px rgba(155,155,155,0.3),
		0px 2px 2px rgba(0,0,0,0.1);
}
.ac-container div[id^="leaflet-control-accordion-layers"] > label:hover:after,
.ac-container input.menu:checked + div[id^="leaflet-control-accordion-layers"] > label:hover:after{
	content: '';
	position: absolute;
	width: 24px;
	height: 24px;
	right: 13px;
	top: 7px;
	background: transparent url(images/arrow_down.png) no-repeat center center;
}
.ac-container input.menu:checked + div[id^="leaflet-control-accordion-layers"] > label:hover:after{
	background-image: url(images/arrow_up.png);
}
.ac-container input.menu{
	display: none;
}
.ac-container article{
	background: rgba(255, 255, 255, 0.5);
	margin-top: -1px;
	overflow: hidden;
	height: 0px;
    padding: 0px;
    line-height: 0px;
	position: relative;
	z-index: 10;
	-webkit-transition: height 0.3s ease-in-out, box-shadow 0.6s linear;
	-moz-transition: height 0.3s ease-in-out, box-shadow 0.6s linear;
	-o-transition: height 0.3s ease-in-out, box-shadow 0.6s linear;
	-ms-transition: height 0.3s ease-in-out, box-shadow 0.6s linear;
	transition: height 0.3s ease-in-out, box-shadow 0.6s linear;
}

.ac-container input.menu:checked ~ article{
	-webkit-transition: height 0.5s ease-in-out, box-shadow 0.1s linear;
	-moz-transition: height 0.5s ease-in-out, box-shadow 0.1s linear;
	-o-transition: height 0.5s ease-in-out, box-shadow 0.1s linear;
	-ms-transition: height 0.5s ease-in-out, box-shadow 0.1s linear;
	transition: height 0.5s ease-in-out, box-shadow 0.1s linear;
}

.ac-container input.menu:checked ~ article.ac-large{
	height: auto;
	max-height : 100px;
	padding: 8px 0;
	overflow-y: auto;
    line-height: 18px;
}

.ac-container article label {
    display: inline;
    cursor: pointer;
}

.ac-container .group-toggle-container {
    text-align: right;
    margin-right: 3px;
    line-height: 0px;
    display: none;
    height: 20px;
}

.ac-container input.menu:checked ~ .group-toggle-container {
    display: block;
	line-height: 1em;
}

.menu-item-radio{
	font-family: 'Ubuntu-Regular', Arial, sans-serif;
	font-size: 13px;

}

.menu-item-checkbox{
	font-family: 'Ubuntu-Regular', Arial, sans-serif;
	font-size: 13px;

}

.bt_delete{
    position: relative;
	float: right;
	background-image: url(images/delete.png);
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    border: none;
    cursor: pointer;
    height: 16px;
    width: 16px;
    vertical-align: middle;
}

.leaflet-control-layers-expanded {
    padding: 5px;
}

.leaflet-control-layers:hover {
	box-shadow: 0 1px 5px rgba(0,0,0,0.4);
	background: #e0e3ec url(images/bgnoise_lg.jpg) repeat top left;
	border-radius: 5px;
}
