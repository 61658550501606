.leaflet-touch .leaflet-bar,
.leaflet-touch .leaflet-control-layers {
    border: 2px solid rgba(255, 255, 255, 0.4);
}

.leaflet-bar a {
    background-color: rgba(59, 76, 12, 0.5);
    color: #fff !important;
}

.leaflet-touch .leaflet-control-layers {
    background-color: rgba(59, 76, 12, 0.5);
}

.leaflet-control-fullscreen a {
    background: rgba(59, 76, 12, 0.5) url(../leaflet/fullscreenBlc.png) no-repeat 2px 2px !important;
}

.leaflet-touch.leaflet-fullscreen-on .leaflet-control-fullscreen a {
    background-position: 2px -24px !important;
}

.leaflet-bar a:hover {
    background-color: rgb(59, 76, 12) !important;
    color: #fff !important;
}


/* gestion du z_index des popus par rapports aux z_index des layers */

.leaflet-popup-pane {
    z-index: 1000 !important;
}


/* gestion de la recuperation du code dans un popup pour le passer au tableau de recherche */

.recherche {
    cursor: pointer;
    color: blue;
}


/* style personnalisé du control des layers */

.leaflet-control-layers-toggle {
    background-image: url(../leaflet/images/layers-2x.png) !important;
    width: 52px !important;
    height: 52px !important;
}

div[id^="leaflet-control-accordion-layers"]>label {
    font-family: inherit !important;
}

.menu-item-radio {
    font-family: inherit !important;
    font-size: 14px !important;
}

.menu-item-checkbox {
    font-family: inherit !important;
    font-size: 14px !important;
}

input[type="checkbox"],
input[type="radio"] {
    margin: 10px 15px 5px 5px;
}

#menuCarto input[type="checkbox"] {
    margin: 0 0 0 15px !important;
}

#menuCarto select {
    color: black;
}

.leaflet-control-layers-expanded,
.leaflet-control-layers:hover {
    background: rgba(59, 76, 12, 0.5)!important;
    border: 2px solid rgba(255, 255, 255, 0.4)!important;
    box-shadow: none !important;
    background-clip: padding-box!important;
    width: 250px !important;
}

#menuCarto .leaflet-control-layers-scrollbar {
    overflow-y: hidden;
    height: 100% !important;
}

.leaflet-left {
    z-index: 500;
}

.ac-container input.menu:checked+label,
.ac-container input.menu:checked+label:hover {
    background: #10662e !important;
    color: #fff !important;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 0 1px rgba(155, 155, 155, 0.3), 0 2px 2px rgba(0, 0, 0, 0.1);
}

.ac-container{
    width: 235px !important;
}

.ac-container article {
    background: #fff !important;
    width: 250px;
}
.ac-large {
    width: 100% !important;
}

.ac-container input.menu:checked~article.ac-large {
    max-height: 64vh;
}

.leaflet-bar a.leaflet-disabled {
    background-color: rgb(59, 76, 12);
}

.leaflet-control-coordinates {
    background: rgba(53, 111, 180, 0.5)!important;
    border: 2px solid rgba(255, 255, 255, 0.4)!important;
    background-clip: padding-box!important;
    color: #fff;
    border-radius: 4px;
    box-shadow: none;
    text-shadow: 0 0 1px black;
}

#menuCarto .leaflet-control {
    background: none !important;
    box-shadow: none !important;
    border: none !important;
    float: none;
}

#menuCarto .leaflet-control-layers-overlays>.leaflet-control-accordion-layers-0>input label {
    padding-left: 10px;
}

#menuCarto .ac-large {
    overflow-y: auto;
    width: 100%;
}

#menuCarto .ac-container article {
    background: #00963e !important;
    margin-top: 0;
    line-height: 22px;
    color: white;
}

#menuCarto .ac-container article label {
    margin-left: 5px;
}

#menuCarto .ac-container div[id^="leaflet-control-accordion-layers"]>label:after,
.ac-container input.menu:checked+div[id^="leaflet-control-accordion-layers"]>label:after {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    right: 13px;
    top: 10px;
    background: transparent url(../leaflet/images/arrow_down.png) no-repeat center center;
}

#menuCarto div section {
    height: 100% !important;
    max-height: 100% !important;
}

div[id^="leaflet-control-accordion-layers"]>label {
    padding: 5px 20px 5px 5px;
    background: #08883c;
    box-sizing: content-box;
    cursor: pointer;
    width: auto;
    margin: 0;
    color: white;
}

div[id^="leaflet-control-accordion-layers"]>label:hover {
    background: #08883c;
}

div[id^="leaflet-control-accordion-layers"]>label {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
}


/* css pour les markers points techniques  */

.marker-pointsTech-small {
    background-color: rgba(30, 144, 155, 0.6);
}

.marker-pointsTech-small div {
    background-color: rgba(65, 105, 225, 0.6);
}

.marker-pointsTech-medium {
    background-color: rgba(0, 191, 255, 0.6);
}

.marker-pointsTech-medium div {
    background-color: rgba(30, 144, 255, 0.6);
}

.marker-pointsTech-large {
    background-color: rgba(0, 255, 255, 0.6);
}

.marker-pointsTech-large div {
    background-color: rgba(0, 191, 255, 0.6);
}


/* IE 6-8 fallback colors */

.leaflet-oldie .marker-pointsTech-small {
    background-color: rgb(181, 226, 140);
}

.leaflet-oldie .marker-pointsTech-small div {
    background-color: rgb(110, 204, 57);
}

.leaflet-oldie .marker-pointsTech-medium {
    background-color: rgb(241, 211, 87);
}

.leaflet-oldie .marker-pointsTech-medium div {
    background-color: rgb(240, 194, 12);
}

.leaflet-oldie .marker-pointsTech-large {
    background-color: rgb(253, 156, 115);
}

.leaflet-oldie .marker-pointTech-large div {
    background-color: rgb(241, 128, 23);
}

.marker-pointsTech {
    background-clip: padding-box;
    border-radius: 20px;
}

.marker-pointsTech div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;
    text-align: center;
    border-radius: 15px;
    font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.marker-pointsTech span {
    line-height: 30px;
}

.marker-button {
    width: auto;
    padding: 0 10px;
    line-height: 1.4;
    display: inline-block;
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
    .leaflet-control-fullscreen a {
        background-image: url(../leaflet/fullscreenBlc@2x.png);
    }
}